.App {
  text-align: left;
  font-family: Arial, 'Helvetica Neue', sans-serif;
  font-size: 18px;
  color: white;
  padding: 0px;
  padding-top: 0px;
  overscroll-behavior: contain;
  background-color: transparent;
  /* background-color is set in index.css */
}

html, body, #root, .App {
  height: 100%;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.titleWrapper {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 2vw;
}

.titleStrategy {
  font-size: 2vw;
  text-Align: center;
  color: #B0EFBE;
}


@media only screen and (max-width: 1800px) {
.buySellContainer {
  display: grid;
    grid-template-columns: 8vw 25vw 25vw 25vw 2vw;
    /* 140px 130px 380px 380px 380px 120px 180px; */
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0vw;
  margin-right: 0vw;
  max-width: 500px;
  text-align: center;
  float: center;
}
.debtContainer {
  display: grid;
    grid-template-columns: 8vw 75vw 2vw;
    /* 140px 130px 380px 380px 380px 120px 180px; */
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0vw;
  margin-right: 0vw;
  max-width: 500px;
  text-align: center;
  float: center;
}

.incomeContainer {
  display: grid;
  grid-template-columns: 8vw 25vw 25vw 25vw 2vw;
  max-width: 500px;
  align-content: center;
}

.crossNarrativeContainer {
  display: grid;
  grid-template-columns: 8vw 75vw 5vw;
  /* 140px 130px 1140px 120px 180px; */
  padding-top: 0px;
  padding-bottom: 0px;
  /* margin-top: 20px; */
  margin-top: 0vmin;
}

.addBlock {
  font-Size: 1.6vw;
    border-Radius: 20px;
    margin: 4.5vw 1.2vw 4.5vw 1.2vw;
    padding: 1.4vw 4vw 1.4vw 4vw;
    line-Height: 1.8vw;
    text-Align: center;
      font-Weight: bolder;
      cursor: pointer;
}

.addBlockTiny {
  font-Size: 1vw;
    border-Radius: 10px;
    margin: 0vw;
    padding: 0.5vw 0vw 0.5vw 0vw;
    line-Height: 1.8vw;
    text-Align: center;
      font-Weight: bolder;
      cursor: pointer;
    background-image: linear-gradient(180deg, #f8767692, #f8767692)
}

.componentTitle {
  font-size: 1.6vw;
  line-Height: 2vw;
  text-align: center;
  font-weight: bolder;
  vertical-align: top;
  padding-top: 0px;
  margin-bottom: 0px;
}


.column {
  height: 1vmax;
  margin-left: 2vw;
  padding-left: 2vw;
  margin-right: 2vw;
  border-radius: 20px;
}
.columnEmpty {
  border: 0px;
  text-align: center;
  font-size: 1.8vmin;
  margin-left: 0vw;
  padding-left: 0vw;
}
  
.titleItemLarge {
  border: 0px;
  text-align: center;
  vertical-align: middle;
  border-color: transparent;
  /* This assumes parent column height is same height: */
  /* line-height: 180px; */
  line-height: 2vw; 
  font-size: 1.8vw;
  padding: 0.5vw 0px 0px 0px;
}

.bracketItemLarge{
  font-size: 4vw;
  border: 0px;
  text-align: center;
  vertical-align: middle;
  /* This assumes parent column height is same height: */
  /* line-height: 180px; */
  line-height: 2vw; 
  padding-left: 0px;
  padding-top: 0vw;
  color: #B0EFBE;
  opacity: 0.5;
}

.columnForBuySell {
  display: flex;
  overflow: auto;
  flex-direction: column;
  box-sizing: border-box;
  height: 13vw;
  padding: 0.75vw 1.25vw 0vw 1.25vw;
  margin-left: 1.25vw;
  margin-right: 1.25vw;
  background-image: linear-gradient(180deg, #0094d9,#0073a8 );

  border-radius: 15px;
  margin-bottom: 5px;
  margin-top: 0.5vw;
}


.columnForIncome {
  display: flex;
  overflow: auto;
  flex-direction: column;
  box-sizing: border-box;
  height: 11vw;
  /* padding: 0px 0.5vw 0px 0.5vw; */
  padding: 0.75vw 1.25vw 0vw 1.25vw;
  margin-left: 1.25vw;
  margin-right: 1.25vw;
  background-image: linear-gradient(180deg, #0094d9,#0073a8 );
  border-radius: 15px;
  margin-bottom: 0px;
}

.columnForDebt {
  display: flex;
  overflow: auto;
  flex-direction: column;
  box-sizing: border-box;
  /* height: 4.0vw; */
  /* padding: 0px 0.5vw 0px 0.5vw; */
  padding: 0.25vw 1.25vw 0.25vw 1.25vw;
  margin-left: 1.25vw;
  margin-right: 1.25vw;
  /* padding-bottom: 2vw; */
  background-image: linear-gradient(180deg, #0094d9,#0073a8 );
  border-radius: 15px;
  margin-bottom: 0px;
}

.columnForMetric {
  display: flex;
  overflow: auto;
  flex-direction: column;
  box-sizing: border-box;
  height: 3vw;
  padding: 0.3vw;
  margin-left: 1.25vw;
  margin-right: 1.25vw;
  background-color: #270364;
  background-image: linear-gradient(180deg, #0094d9,#0073a8 );

  border-radius: 10px;
  margin-bottom: 5px;
  margin-top: 0.75vw;
  font-Size: 1.8vw;
  font-Weight: bold;
}

.columnForIncomeDate {
  display: flex;
  overflow: auto;
  flex-direction: column;
  box-sizing: border-box;
  height: 11vw;
  padding: 0.75vw 1.0vw 0vw 1.0vw;
  margin-left: 1.25vw;
  margin-right: 1.25vw;
  background-image: linear-gradient(180deg, #0094d9,#0073a8 );

  border-radius: 15px;
  margin-bottom: 10px;
}

.labelDateText {
  font-size: 1.25vw;
  color:#000000;
  background-color: white;
  text-align: center;
  margin: 3px 1vw 0vw 1vw;
  padding: 2px 0vw 2px 0vw;
  box-sizing: border-box;
  border: solid;
  border-radius: 10px;
  border-color: white;
  border-width: 0.5px;
  cursor: pointer;
}

.labelDateTextSmall {
  font-size: 1.25vw;
  color: black;
  background-color: white;
  text-align: center;
  margin: 3px 1vw 0vw 1vw;
  padding: 2px 0vw 2px 0vw;
  box-sizing: border-box;
  border: solid;
  border-radius: 10px;
  border-color: white;
  border-width: 0.5px;
  cursor: pointer;
}


  .labelDateTextNoBorder {
    font-size: 1.25vw;
    text-align: center;
    margin: 3px 1vw 0vw 1vw;
    padding: 0px 0vw 0px 0vw;
    box-sizing: border-box;
    border: none;
  }

  .inputStyling {
    padding-top:0px;
    padding-bottom: 0px;
    box-sizing: border-box;
    float: center;
    text-align: center;
  }

  .number-Format {
    margin-right: 10px;
  }

  .labelLeftText {
    font-size: 1.6vw;
    text-align: left;
    margin: 0vw 0vw 0vw 0vw;
    padding: 0vw 0vw 0vw 0vw;
    float: left;
    /* margin-top: 5px; */
    margin-top: 0.25vw;
  }

  .theYieldComponentOne {
    font-Size: 1.6vw;
    margin-top: 0.25vw;
    margin-left: -1vw;
    margin-right: 1.25vw;
    cursor: 'pointer';
  }

  .theYieldComponentTwo {
    font-Size: 1.6vw;
    margin-top: 0.25vw;
    margin-left: -1vw;
    cursor: 'pointer';
  }

}


@media only screen and (min-width: 1801px) {
  .buySellContainer {
    border: 0px transparent;
    display: grid;
      grid-template-columns: 8vw 450px 450px 450px 2vw;
    max-width: 500px;
  }
  .debtContainer {
    border: 0px transparent;
    display: grid;
      grid-template-columns: 8vw 1350px 2vw;
    max-width: 500px;
  }
  .incomeContainer {
    display: grid;
    grid-template-columns: 8vw 450px 450px 450px 2vw;
    max-width: 500px;
    align-content: center;
  }
  .crossNarrativeContainer {
    display: grid;
    grid-template-columns: 8vw 1350px 2vw;
    max-width: 500px;
    align-content: center;
    text-align: center;
  }

  .column {
    margin-left: 2vw;
    padding-left: 2vw;
    margin-right: 2vw;
    border-radius: 20px;
  }
  
  .columnEmpty {
    border: 0px;
    text-align: center;
    font-size: 30px;
    margin-left: 0vw;
    padding-left: 0vw;
  }

  .addBlock {
  font-Size: 25px;
  border-Radius: 20px;
  margin: 80px 20px 80px 20px;
  padding: 30px 60px 30px 60px;
  line-Height: 40px;
  text-Align: center;
    font-Weight: bolder;
    cursor: pointer;
  }

  .componentTitle {
  font-size: 30px;
  text-align: center;
  font-weight: bolder;
  vertical-align: top;
  line-Height: 40px;
  padding-top: 0px;
  margin-bottom: 0px;
  }

  .titleItemLarge {
    border: 0px;
    text-align: center;
    vertical-align: middle;
    /* This assumes parent column height is same height: */
    /* line-height: 180px; */
    line-height: 40px; 
    font-weight: 100;
    font-size: 30px;
    padding: 10px 0px 0px 0px;
    margin: 0px;
  }
  
  .bracketItemLarge{
    font-size: 4vw;
    border: 0px;
    text-align: center;
    vertical-align: middle;
    /* This assumes parent column height is same height: */
    /* line-height: 180px; */
    line-height: 2vw; 
    padding-left: 0px;
    padding-top: 0vw;
    color: #B0EFBE;
    opacity: 0.5;
  }

  .columnForBuySell {
    display: flex;
    overflow: auto;
    flex-direction: column;
    box-sizing: border-box;
    height: 250px;
    padding: 0.3vw;
    margin-left: 15px;
    margin-right: 15px;
    background-image: linear-gradient(180deg, #0094d9,#0073a8 );
    border-radius: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  
  .columnForIncome {
    /*display: flex;
    overflow: auto;
    flex-direction: column;*/
    box-sizing: border-box;
    height: 250px;
    font-size: 30px;
    padding: 20px;
    margin-left: 15px;
    margin-right: 15px;
    background-image: linear-gradient(180deg, #0094d9,#0073a8 );
    border-radius: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .columnForDebt {
    /*display: flex;
    overflow: auto;
    flex-direction: column;*/
    box-sizing: border-box;
    height: 250px;
    font-size: 30px;
    padding: 20px;
    margin-left: 15px;
    margin-right: 15px;
    background-image: linear-gradient(180deg, #0094d9,#0073a8 );
    border-radius: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .columnForMetric {
    display: flex;
    overflow: auto;
    flex-direction: column;
    box-sizing: border-box;
    height: 50px;
    font-size: 30px;
    padding: 0.3vw;
    padding: 0.3vw, 20px, 0.3vw, 20px;
    margin-left: 15px;
    margin-right: 15px;
    background-color: #270364;
    background-image: linear-gradient(180deg, #0094d9,#0073a8 );

    border-radius: 10px;
    margin-bottom: 5px;
    margin-top: 0.75vw;
    font-Weight: bold;

    text-align: center;
    float: center;
  }

  .addItem {
    display: flex;
    overflow: auto;
    flex-direction: column;
    box-sizing: border-box;
    border: 1px dashed rgb(158, 48, 48);
    border-radius: 10px;
    width: 80px;
    text-align: center;
    cursor:'pointer';
  }

  .inputStyling {
    padding-top:0px;
    padding-bottom: 0px;
    font-size: 30px;
    float: center;
    text-align: center;
  }

  .groupedItems {
    text-align: center;
  }

  .columnForIncomeDate {
    
    box-sizing: border-box;
    height: 250px;
    padding: 20px;
    margin-left: 1.25vw;
    margin-right: 1.25vw;
    background-image: linear-gradient(180deg, #0094d9,#0073a8 );
  
    border-radius: 15px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .labelDateText {
    font-size: 22px;
    color:#000000;
    background-color: white;
    text-align: center;
    margin: 3px 10px 0vw 10px;
    padding: 2px 0vw 2px 0vw;
    box-sizing: border-box;
    border: solid;
    border-radius: 10px;
    border-color: white;
    border-width: 0.5px;
  }

  .labelDateTextSmall {
    font-size: 22px;
    color: black;
    background-color: white;
    text-align: center;
    margin: 3px 10px 0vw 10px;
    padding: 2px 0vw 2px 0vw;
    box-sizing: border-box;
    border: solid;
    border-radius: 10px;
    border-color: white;
    border-width: 0.5px;
    cursor: pointer;
  }


  .labelDateTextNoBorder {
  font-size: 22px;
  text-align: center;
  margin: 3px 1vw 0vw 1vw;
  padding: 2px 0vw 2px 0vw;
  box-sizing: border-box;
  border: none;
}

  .labelLeftText {
    font-size: 30px;
    margin: 0px;
    padding: 0px;
  }

  .theYieldComponentOne {
    font-Size: 30px;
    margin-top: 0.25vw;
    margin-left: 0.75vw;
    padding: 0px;
    cursor: 'pointer';
  }
  
}






.titleItem {
  border: 0px;
  text-align: center;
  vertical-align: middle;
  /* This assumes parent column height is same height: */
  /* line-height: 120px;  */
  line-height: 1.5vh; 

  font-weight: 100;
  font-size: 1.8vw;
  color: #B0EFBE;
}

.bracketItem{
  font-size: 6vw;
  border: 0px;
  text-align: center;
  vertical-align: middle;
  /* This assumes parent column height is same height: */
  /* line-height: 120px; */
  line-height: 1.8vh; 
  padding-left: 35px;
  padding-right: 25px;
  color: #B0EFBE;
  opacity: 0.5;
}



span {
  display: inline-block;
  vertical-align: middle;
  line-height:normal;
}


.currencyInput {
  /* border: 1px solid white; */
  text-align: center;
  margin-left: 25px;
  padding-right: 0px;

  font-size: 24px;
  background-color: transparent;
  color: white;
  border-radius: 0px;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
}

.flexParent {
  display: inline-block;
  white-space: nowrap;
  padding-left: 3px;
  padding-right: 3px;
  vertical-align: middle;
}

.labelAndInput {
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 10px;
  /* position: relative; */
  /* display: inline-block; */
  /* align-content: space-between; */
  /* white-space: nowrap; */
}


.spanInput {
  width: 90%;
  box-sizing: border-box;
  /* display: block;
  overflow: hidden; */
  /* margin: 0px;
  padding: 0px; */
  /* padding: 0.5vw 1vw 0vw 1vw; */
  text-align:  center;
}



.labelCenterText {
  font-size: 1.4vw;
  text-align: center;
  margin: 0vw 0vw 0vw 0vw;
  padding: 0vw 0vw 0vw 0vw;
  float: center;
  margin-top: 3px;
}




.labelSmallTextNoBorder {
  font-size: 1.0vw;
  text-align: center;
  margin: 3px 1vw 0vw 1vw;
  padding: 0px 0vw 10px 0vw;
  box-sizing: border-box;
  border: none;
}

.addItem {
  border: 1px dashed white;
  width: 14vw;
  border-radius: 10px;
  /* font-size: 1.4vw; */
  box-sizing: border;
  float: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.backdrop {
  position: fixed;
  backdrop-filter: blur(4px);
  z-index: 5;
  background-color: rgba(16, 20, 125, 0.35);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.backgroundEmoji {
  position: absolute;
  opacity: 100%;
  z-index: 6;
  top: -70px;
  left: -40px;
  font-size: 100px;
}

.backgroundSideEmoji {
  position: absolute;
  opacity: 100%;
  z-index: 6;
  top: 100px;
  right: -170px;
  font-size: 300px;
}

.modal {
  border-radius: 20px;
  padding: 1rem;  

  /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
  font-size: 24px; 
  text-align: center;
  
  /* width: 50%; */
  z-index: 20;
  position: absolute;

  float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* top: 15vh;
  left: 15vw;
  right: calc(25% - 15vw); */
  color: #38336E; 
  /* background-image: linear-gradient(180deg, #0094d9,#006390 ); */
  /* background-image: linear-gradient(180deg, #5eaed3,#45758c ); */
  background-image: linear-gradient(180deg, #7acef4,#5c99b6 );
  opacity: 88%;
}

.modal::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  top: 40px;
  right: 0;
  left: 0;
  background: #000000;
  mix-blend-mode: color-dodge;
  filter: blur(30px);
  opacity: 0.5;
}

.calendarmodal {
  border-radius: 20px;
  padding: 1rem;  

  /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
  font-size: 24px; 
  text-align: center;
  
  /* width: 50%; */
  z-index: 20;
  position: absolute;

  float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* top: 15vh;
  left: 15vw;
  right: calc(25% - 15vw); */
  color: #38336E; 
  /* background-image: linear-gradient(180deg, #0094d9,#006390 ); */
  /* background-image: linear-gradient(180deg, #5eaed3,#45758c ); */
  background-image: linear-gradient(180deg, #7acef4,#5c99b6 );
  opacity: 100%;
}

.calendarmodal::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  top: 40px;
  right: 0;
  left: 0;
  background: #000000;
  mix-blend-mode: color-dodge;
  filter: blur(30px);
  opacity: 0.5;
}

.theportal {
  border-radius: 20px;
  padding: 1rem;  

  /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
  font-size: 24px; 
  text-align: center;
  
  z-index: 10;
  position: fixed;

  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  height: 50%;
  width: 50%;
  max-width: 500px;
  
  /* top: 15vh;
  left: 15vw;
  right: calc(25% - 15vw); */
  /* color: #38336E;  */
  color: white;
  background-color: rgb(79, 79, 79);
  opacity: 1.0;
}

.theportal::before {
  content: "'";
  display: block;
  position: absolute;
  z-index: 5;
  height: 100%;
  width: 100%;
  top: 40px;
  right: 0;
  left: 0;
  background: #000000;
  mix-blend-mode: color-dodge;
  filter: blur(30px);
  opacity: 0.5;
}

.form-Fieldset {
  border: 0px;
}

.input[type=number] {
  border-color: white;
  color:#38336E;
  text-align: center;
  background-color: transparent;
  width: 50px; 
}


.relativeDiv {
  position:relative;
}
.divTipContext {
  font-size: 0.8vw;
  text-align: center;
  position: absolute;
  bottom: 0px;
}


.divHeader {

  margin: 0px;
  padding: 0px;
  padding-top: 5px;
}

.divFooter {
  position:fixed;
  left:10px;
  bottom:10px;
  right:0;
  background-color: transparent;
  background-image: transparent;
  /* color: #38336E;   */
  border-top: 1x white solid;
  /*background-color:#38336E; */
}

.bookmarker {
  position: absolute;
  top: 0px;
  right: 0px;
  padding-top: 2px;
  padding-right: 5px;
  height: 25px! important;
}

.countryFlag {
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding-bottom: 10px;
  padding-right: 10px;
  height: 4vw! important;
  opacity: 1.0; 
}

.countryFlag::before {
  content: "'";
  display: block;
  position: absolute;
  z-index: 5;
  height: 100%;
  width: 100%;
  top: 40px;
  right: 0;
  left: 0;
  background: #000000;
  mix-blend-mode: color-dodge;
  filter: blur(30px);
  opacity: 0.5;
}

.arrival-animate-item {
  background-image: linear-gradient(to bottom, orange, orange);
  background-color:rgba(255, 165, 0, 0.1);
  background-size:0 100%;
  background-repeat:no-repeat;
  height: 20px;
  width: 100px;
  float: left;
  margin-right: 10px; 
  transition: 5s;
}

.arrival-animate-item.active {
  background-size: 100% 100%;
}

@media only screen and (max-height: 450px) {
  .divFooter {
    position:absolute;
    left:0;
    bottom:0;
    right:0;
    height:0! important;
    background-color: transparent;
  }
}

:target td {
  animation: highlight 2s;
}

@keyframes highlight {
  0% {
    background-color: green;
  }
  50% {
    background-color: initial;
  }
}

@media only screen and (max-width: 580) {
  .bookmarker {
    position: absolute;
    top: 0px;
    right: 0px;
    padding-top: 0px;
    padding-right: 0px;
    width: 0px;
    height: 0px! important;
  }
}

#clicky-logo {
  display: none;
}

