/* Mobile Class */
.App {
  overscroll-behavior: contain;
}
.body {
  overscroll-behavior: contain;
  position: fixed;
}

.crossNarrativeContainerMobile {
    display: grid;
    grid-template-columns: auto;
    /* 140px 130px 1140px 120px 180px; */
    padding-top: 0px;
    padding-bottom: 0px;
    /* margin-top: 20px; */
    margin-top: 1.2vmin;
}

.buySellContainerMobile {
  
    /* 140px 130px 380px 380px 380px 120px 180px; */
  padding-top: 0px;
  padding-right: 5px;
  padding-left: 5px;
}

.incomeContainerMobile {
  grid-template-columns: 25vw 25vw 25vw;
  /*max-width: 500px;*/
  align-content: center;
  padding-top: 45px;
  padding-right: 0px;
  padding-left: 0px;
}

.debtContainerMobile {
  align-content: center;
  padding-top: 0px;
  padding-right: 2px;
  padding-left: 2px;
}

.topContainerMobile {
  display: contents;
  justify-items: space-between;
/*  grid-template-columns: 30vw 30vw 30vw; */
  max-width: auto;
  align-content: center;
  padding-top: 45px;
  
}

.returnNarrativeMobile {
  display: flow-root;
  display: grid;
  grid-template-columns: 33vw 33vw 33vw;
    /* 140px 130px 380px 380px 380px 120px 180px; */
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0vw;
  margin-left: 0vw;
  margin-right: 0vh;
  
  text-align: center;
  float: center;
}

.returnNarrativeMobilePinnedToTop {
  /* display: inline-flex;
  /* justify-items: space-between; 
    /*grid-template-columns: 33vw 33vw 33vw; */
  display: grid;
    grid-template-columns: 33vw 33vw 33vw; 
  position: fixed;
  top: 30px;
  left: 0px;
  height: 50px;
  width: 100%;
  z-index: 1;
  /* background-color: #007a0a; */
}

.shareReportMobilePinnedToBottom {
  display: grid;
  /* grid-template-columns: 33vw 33vw 33vw;  */
  position: fixed;
  bottom: 0px;
  left: 0px;
  height: 50px;
  width: 100%;
  z-index: 1;
}

.emailCommandPinnedToBottom {
  position: fixed;
  bottom: 0px;
  left: 0px;
  height: 50px;
  width: 100%;
  z-index: 1;
}

.groupedItemsMobile {
  text-align: center;
  box-sizing: border-box;
  margin-left: 5px;
}

.labelDateTextMobile {
  white-space: nowrap;
  font-size: 1.0rem;
  color:#000000;
  background-color: white;
  text-align: center;
  box-sizing: border-box;
  border: solid;
  border-radius: 8px;
  border-color: white;
  border-width: 0.5px;
  padding: 2px;
  margin-left: 5px;
}

.labelDateTextNoBorderMobile {
  font-size: 0.6rem;
  text-align: center;
  box-sizing: border-box;
  border: none;
}

.componentTitleMobile {
    color: white;
    font-size: 1.0rem;
    white-space: nowrap;
    vertical-align: top;
  }

.inputStylingMobile {
    padding-top:0px;
    padding-bottom: 0px;
    font-size: 10px;
    /* float: center; */
    text-align: right;
  }

.addBlockMobile {
    font-Size: 1.0rem;
    color: white;
      border-Radius: 20px;
      margin: 25px;
      padding: 25px;
      line-Height: 0px;
      text-Align: center;
        font-Weight: bolder;
        cursor: pointer;
        margin-top: 20px;
        margin-bottom: 20px;
 }

 .columnEmptyMobile {
  border: 0px;
  text-align: center;
  font-size: 40px;
  margin-left: 0vw;
  padding-left: 0vw;
}

.modalMobile {
  border-radius: 20px;
  padding: 5px;

  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  font-size: 18px; 
  text-align: center;
  
  /* width: 50%; */
  z-index: 20;
  position: absolute;

  float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 65%;
  color: #38336E; 
  background-image: linear-gradient(180deg, #0094d9,#0073a8 );
  /* background-color: white; */
}

.titleItemLargeMobile {
  border: 0px;
  text-align: center;
  vertical-align: middle;
  border-color: transparent;
  /* This assumes parent column height is same height: */
  /* line-height: 180px; */
  line-height: 40px; 
  font-weight: 100;
}

.columnForLoanBlockMobile {
  flex-direction: column;
  box-sizing: border-box;
  height: auto;
  padding: 0px;
  
  margin-left: 1px;
  margin-right: 1px;
  /*border: 1px solid grey;
  background-color: rgba(45, 154, 106, 0.609); */
  background-image: linear-gradient(180deg, #4e4e4e,#313131 );
  border-radius: 10px;
  margin-bottom: 10px;
  margin-top: 5px;
  padding-bottom: 5px;
}

.columnForLoanBlockMobileOn {
  flex-direction: column;
  box-sizing: border-box;
  height: auto;
  padding: 0px;
  
  margin-left: 1px;
  margin-right: 1px;
  background-image: linear-gradient(180deg, #e17400,#b85f00 );
  border-radius: 10px;
  margin-bottom: 10px;
  margin-top: 5px;
  padding-bottom: 5px;
}

.columnForIncomeMobile {
  /* display: flex; */
  /* overflow: auto; */
  flex-direction: column;
  box-sizing: border-box;
  height: auto;
  padding: 0px;
  
  margin-left: 1px;
  margin-right: 1px;
  /*border: 1px solid grey;
  background-color: rgba(45, 154, 106, 0.609); */
  background-image: linear-gradient(180deg, #0094d9,#0073a8 );
  border-radius: 10px;
  margin-bottom: 10px;
  margin-top: 5px;
  padding-bottom: 5px;
}



.columnForBuySellMobile {
  box-sizing: border-box;

  /* background-image: linear-gradient(180deg, #0094d9,#0073a8 ); */
  /* background-image: linear-gradient(180deg, #421bad,#34158a ); */
  /* background-image: linear-gradient(180deg, #e97800,#c46500 ); */
  background-image: linear-gradient(180deg, #008d85,#006560 );
  border-radius: 10px;
  margin-bottom: 5px;
  padding-bottom: 5px;
  /* padding-right: 5px; */
}



.labelCenterTextMobile {
  font-size: 0.9rem;
  text-align: center;
  margin: 0vw 0vw 0vw 0vw;
  padding: 0vw 0vw 0vw 0vw;
  float: center;
  margin-top: 0px;
}

.labelShareTextMobile {
  font-size: 1.2rem;
  text-align: center;
  margin: 0vw 0vw 0vw 0vw;
  padding: 0vw 0vw 0vw 0vw;
  float: center;
  margin-top: 0px;
}

.flexParentMobile {
  /* white-space: wrap; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1vw;
  padding-right: 1vw;
  /*vertical-align: middle;*/
  padding-top: 5px;
  padding-right: 0px;
  margin-left: 2vh;
  margin-right: 2vh;
  
}

.flexParentYieldMobile {
  /* white-space: wrap; */
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 1vw;
  padding-right: 1vw;
  /*vertical-align: middle;*/
  padding-top: 5px;
  padding-right: 0px;
  margin-left: 2vh;
  margin-right: 2vh;
  
}



.labelLeftTextMobile {
  display: flex-inline;
  white-space: nowrap;
  color: white;
  font-size: 0.9rem;
  text-align: left;
  margin: 0vw 0vw 0vw 0vw;
  padding: 0vw 0vw 0vw 0vw;
  float: left;
  margin-top: 0px;
}

.labelSmallTextNoBorderMobile {
  font-size: 0.8rem;
  text-align: center;
  margin: 3px 1vw 0vw 1vw;
  padding: 0px 0vw 10px 0vw;
  box-sizing: border-box;
  border: none;
}


.theYieldComponentOneMobile {
  font-Size: 1.0rem;
  margin-top: 0.25vw;
  margin-right: 1.25vw;
  cursor: 'pointer';
  text-align: left;
}

.addItemMobile {
  border: 1px dashed white;
  color: white;
  width: 14vw;
  border-radius: 10px;
  font-size: 10px;
  box-sizing: border;
  float: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.countryFlagMobile {
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding-bottom: 10px;
  padding-right: 10px;
  height: 4vw! important;
  opacity: 0.99; 
}

.countryFlagMobile::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 5;
  height: 100%;
  width: 100%;
  top: 40px;
  right: 0;
  left: 0;
  background: #000000;
  mix-blend-mode: color-dodge;
  filter: blur(30px);
  opacity: 0.5;
}

.divHeaderMobile {
  margin: 0px;
  padding: 0px;
  padding-top: 2px;
  font-size: 7px;
}

.columnForMetricMobile {
  display: grid;
  /*overflow: auto; */
  /* flex-direction: column; */
  box-sizing: border-box;
  /* height: auto; */
  padding: 2px;
  margin-left: 1.2vw;
  margin-right: 1.2vw;
  background-color: #270364;
  background-image: linear-gradient(180deg, #009a0d,#007a0a );
  vertical-align: middle;
  border-radius: 10px;
  margin-bottom: 5px;
  margin-top: 0.75vw;
  font-Size: 1.0rem;
  font-Weight: bold;
  align-items: center;
  justify-items: center;
  text-align: center;
}

.columnForSharingMobile {
  display: grid;
  box-sizing: border-box;
  padding: 0px;
  margin-left: 3px;
  margin-right: 1px;
  color: white;
  background-image: linear-gradient(180deg, rgb(173, 97, 255),rgb(143, 38, 255) );

/*  background-color: #270364;
  background-image: linear-gradient(180deg, #009a0d,#007a0a ); */
  vertical-align: middle;
  border-radius: 15px;
  margin-bottom: 0px;
  margin-top: 0.0vw;
  font-Size: 1.1rem;
  font-Weight: normal;
  align-items: center;
  justify-items: center;
  text-align: center;

}

.rev2-button {
  font-size: 1.0rem;
}